.browser_icon{
margin-bottom: 8px;
}

.browser_item{
  text-align: center;
}

.browser_text{
  font-family: Nunito;
  text-align: center;
  color: #666666;
  margin-bottom: 8px !important;
}

.content{
  max-width: 650px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.container{
  display: grid
}
.main_img{
  margin: auto;
}

.main_text{
  font-family: Nunito-Bold;
  text-align: center;
  color: #001B3B;
  margin: 35px;
}

.secondary_text{
  font-family: Nunito;
  text-align: center;
  color: #666666;
  margin: 45px;

}

.icon {
  font-size: 60px;
  text-align: center;
  width: 100% !important;
}


.info_saludtools{
  position: fixed;
  bottom: 35px;
  right: 0px;
}
.info_saludtools_content{
  margin: auto;
  width: 80%;
  max-width: 200px;
  padding-left: 10px;
  padding-right: 10px;
}
.info_saludtools_name{
font-family: Nunito;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 20px;
color: #666666;
padding-left: 10px;
}
.info_saludtools_img{
  width: 100%;
  text-align: center;
}