.Dropzone {
    width: 90%;
    margin: auto;
    height: 5em;
    background-color: #fff;
    border: 2px dashed grey;
    border-radius: 2em;
    opacity: 0.3;
    visibility: visible;
    display: block;
    text-align: center;
    font-size: 16px;
    line-height: 5em;
    outline: 0;
}

.drop {
    width: 100%;
    height: 100%;
    background: rgba(46, 141, 238, 0.48);
    z-index: 130;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.active:after {
    width: 100%;
    height: 100%;
}

.messages {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
}

.Dropzone p {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}

.Highlight {
    border: 5px dashed green;
}

input#type-a-message {
    display: inline-block;
    width: 500px;
}

/* PLANTILLA */

.user_chat {
  background-color: #fff;
  -webkit-box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.w_100 {
  width: 100% !important;
}
.p_3 {
  padding: 1rem !important;
}
.border_bottom {
  border-bottom: 1px solid #f0eff5 !important;
}
.align_items_center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}
.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.d_block {
  display: block !important;
}
.mr_2, .mx_2 {
  margin-right: .5rem !important;
}

.mr_3, .mx_3 {
  margin-right: 1rem !important;
}

.media_body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.font_size_16 {
  font-size: 16px !important;
}
.text_truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mb-0, .my-0 {
  margin-bottom: 0 !important;
}
.text-reset {
  color: inherit !important;
}
.p-3 {
  padding: 1rem !important;
}
.border-top {
  border-top: 1px solid #f0eff5 !important;
}


/* PLANTILLA */



input {
  clear: both;
}

.input_chat {
  background: #FFFFFF;
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1)  !important;
border-radius: 25.8818px !important;
border-color: #e6ebf5 !important;
padding: .5rem !important;
border-width: 0px !important;
}

.input_chat:focus {

     border-right-width: 1px !important; 
     outline: 0; 
     -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); 
     box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.btn_send {
  background: #5B7FB6 !important;
opacity: 0.7;
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1) !important;
border-radius: 28px  !important;
width: 40px !important ;
height: 40px !important;
top: 10px;
}

.btn_group {
  position: absolute;
  bottom: 11px;
  left: 10px;
  z-index: 2;
}

.header{
    width: 100%;
    padding: 15px;
    background: #FBFBFB;
    box-shadow: 0px 2px 4px rgba(102, 102, 102, 0.2);
    display: flex;
    padding-left: 30px;
    padding-right: 30px;
    font-family: Nunito-Bold;
    font-style: normal;
    color: #666666;
    font-size: 20px;
    position: fixed;
    top: 0;
    z-index: 2;
    left: 0;
    
}

.header_content{
  margin: auto;
  width: 100%;
  max-width: 680px;
  display: flex;
}

.avatar {
  border-radius: 40px !important;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.header_name{
  font-family: Nunito;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 40px;
padding-left: 20px;
color: #666666;
}

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 2;
  width: 100%;
  max-width: 730px;
}

.messages:focus{
  outline: 0;
}
.container{
    position: relative;
    top: 0px;
    background-color: rgb(253, 253, 253);
    height: 100%;
    max-width: 730px;
    margin: auto;
    border-right: #F3F3F3 1px solid;
    border-left: #F3F3F3 1px solid;
}
.container:focus{
  outline: 0; 
  overflow:hidden;
}
html, body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #FDFDFD;
}

.icons_mobile {
  display: flex !important;
  width: auto;
  flex-direction: row;
  position: absolute;
  height: 100%;
  right: 70px;
  justify-content: center;
  align-content: center;
}

.info_saludtools{
  position: fixed;
  bottom: 35px;
  right: 0px;
}
.info_saludtools_content{
  margin: auto;
  width: 80%;
  max-width: 200px;
  padding-left: 10px;
  padding-right: 10px;
}
.info_saludtools_name{
font-family: Nunito;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 20px;
color: #666666;
padding-left: 10px;
}
.info_saludtools_img{
  width: 100%;
}

.attachment_icon_main{
  box-shadow: none !important;
  width: 20px;
  height: 20px;
}

.attachment_icon_main:hover{
  background-color: #C4C4C4;
}

.attachment_icon{
  box-shadow: none !important;
  width: 20px;
  height: 20px;
}

.send_icon {
    width: 20px;
    height: 20px;
    position: relative;
    margin-left: -7px;
    margin-top: 5px;
}

.attachment_icon_mobile {
    width: 15px;
    height: 15px;
}


.input_mobile {
  padding-right: 60px !important;
}

.alertInformation{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
    background-color: #FFF3CD;
    padding: 20px;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 10%);
    border-radius: 5px;
    color: #856404;
    z-index: 1;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    max-width: 650px;
}

.alertIcon {
  width: 100%;
  height: auto;
}


.topContainer {
  width: 100%;
  height: 'auto';
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
}