.incoming_msg_img {
    display: inline-block;
    width: 6%;
}
.received_msg {
    display: inline-block;
    padding: 0 0 0 10px;
    vertical-align: top;
    width: 92%;
}
.received_withd_msg > div {
    background: #aa02aa none repeat scroll 0 0;
    border-radius: 3px;
    color: #333333;
    font-size: 14px;
    margin: 0;
    padding: 5px 10px 5px 12px;
    width: 100%;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: #fff;
}
.time_date {
    color: #747474;
    display: block;
    font-size: 12px;
    margin: 8px 0 0;
}

.outgoing_msg{ overflow:hidden; margin:26px 0 26px;}
.sent_msg {
    display: inline-block;
    float: right;
    width: 70%;
}
.input_msg_write input {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    color: #4c4c4c;
    font-size: 15px;
    min-height: 48px;
    width: 100%;
}

.image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.picture_preview {
  max-height: 'fit-content';
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.picture_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.media {
  border-radius: 4px;
  cursor: pointer;
  margin: 4px;
  display: flex;
  max-width: 220px;
  justify-content: center;
  align-items: center;
}

.placeholder {
    background: rgba(22, 46, 52, 0.58);
}

.medias {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-flow: wrap;
}

.conversation_list {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  max-width: 80%;
}
.ctext_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  
}

.ctext_wrap_content {
  padding: 12px;
  border-radius: 8px 8px 8px 0;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0px 5px 5px 5px;
  margin-left:25px;
  
  min-width: fit-content;
}
.ctext_wrap_content_right {
  padding: 12px 20px;
  border-radius: 8px 8px 8px 0;
  position: relative;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  background-color:  #F0F6FF;
  border-radius: 5px 0px 5px 5px;
  margin-right:25px;
  min-width: fit-content;
}
.ctext_wrap_content:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: -14px;
  top: 0;
  border-style: solid;
  border-width: 20px 0 0 15px;
  border-color:#FFFFFF transparent transparent transparent;
  filter: drop-shadow(-3px 0px 2px rgba(0,0,0,.03));
}

.ctext_wrap_content_right:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  right: -14px;
  top: 0;
  border-width: 20px 15px 0 0;
  border-color: #F0F6FF transparent transparent transparent;
  filter: drop-shadow(3px 2px 2px rgba(0,0,0,.03));
}
  
.mb_0, .my_0 {
  margin-bottom: 0 !important;
}
.chat_time {
  font-size: 12px;
  margin-top: 4px;
  text-align: right;
  color: #666666;
font-family: Nunito;
padding-left: 20px;
}

.right {
  float: right;
  text-align: right;
}
li {
  clear: both;
}
.list_unstyled {
  padding-left: 0;
  list-style: none;
  padding-top: 100px;
}

.list_inline {
  padding-left: 0;
  list-style: none;
}

.message_img {
  border-radius: .2rem;
  position: relative;
}

.list_inline_item {
  display: inline-block;
}

.list_inline_item:not(:last-child) {
  margin-right: .5rem;
}

.message_img_list {
  position: relative;
}

.d_inline_block {
  display: inline-block !important;
}

.m_1 {
  margin: .25rem !important;
}

.message_img img {
  max-width: 150px;
}

.rounded {
  border-radius: .25rem !important;
}

.border {
  border: 1px solid #f0eff5 !important;
}

.message_img_link {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.conversation_name{
  font-family: Nunito-Bold;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 4px;
}

.download_icon {
  width: 39px;
  height: 39px;
  margin-right: 10px;
}

.download_text {
  font-family: Nunito;
  font-size: 15px;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 0;
  color: #666666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.download_title {
  display: inline-flex;
  max-width: 250px;
}