body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.hidden {
  display: none;
}

#root {
    height: 100vh;
}

.conversations-window-wrapper {
  height: inherit;
  background-color: #f0f2f5;
}

.conversations-window-container {
  position: relative;
  max-width: 1268px;
  height: calc(100% - 16px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 8px auto;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), url(../fonts/Nunito-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito-Bold';
  src: local('Nunito-Bold'), url(../fonts/Nunito-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), url(../fonts/Nunito-Regular.ttf) format('truetype');
}

